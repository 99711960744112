import React, { useEffect } from "react"
import styled from 'styled-components'
import Layout from "../components/Layout"
import { toRem } from '../utils/styleUtils'

export const Head = () => (
    <>
            <title>ZipTips Support</title>
            <meta name="description" content="Support for ZipTips app users and creators" />
            <meta name="keywords" content="Swift tips user support help software ios app developer questions how-to about" />

    </>
  )



const Support = () => {
    useEffect(() => {
        typeof window.Beacon === 'function' && window.Beacon('init', 'bfa9b522-9736-4d90-bb9c-e16093234d15');
        return () => typeof window.Beacon === 'function' && window.Beacon('destroy');
    }, []);
    return <Layout navColor="#131313" background="#131313">
        <SupportText>
            <br/>
            <br/><p>Let's do this.</p>
            <p>Please reach out and let us know how we can help.</p>
            <p>Click the blue button below.</p>
            <br/>
            <br/>
        </SupportText>
    </Layout>
}

const SupportText = styled.article`
    
    max-width: 100%;
    margin: auto;
    font-family: Suisse-Regular;
    font-size: ${toRem(25)};
    font-weight: 500;
    line-height: ${toRem(30)};
    text-align: center;
    color: white;
    p {
        margin: ${toRem(32)} 0;
    }
`

export default Support;